export default {
  route: {
    login: '登录',
    dashboard: '首页',
    personalSetting: '个人设置',
    avatarUpload: '头像上传',
    backToTop: '返回顶部',
    i18n: '国际化',
    profile: '个人中心',
    systemSetting: '系统设置',
    organization: '组织架构',
    department: '部门管理',
    personnel: '人员管理',
    position: '岗位管理',
    role: '角色管理',
    messageConfig: '消息配置',
    device: '设备管理',
    product: '产品管理',
    productList: '产品列表',
    output: '输出工艺',
    backend: '后道工艺',
    printingMaterial: '输出材料',
    backendMaterial: '后道材料',
    // 生产管理
    productionManagement: '生产管理',
    productionOrder: '生产订单列表',
    productionOrderChange: '生产订单变更单列表',
    productionOrderDetail: '生产订单详情',
    addProductionOrderChange: '新建生产订单变更单',
    shippingNotice: '发货通知单列表',
    addShippingNotice: '新建发货通知单',
    addProductionOrder: '新建生产订单',
    distributeOrder: '下发生产',
    productionOrderChangeDetail: '生产订单变更单详情',
    // 单据管理
    receiptManagement: '单据管理',
    allReceiptList: '全部单据',
    prepressReceiptList: '印前单据',
    printingReceiptList: '输出单据',
    backendReceiptList: '后道单据',
    productionOrderShopDetail: '门店详情',
    sender: '发件人管理',
    messageCenter: '消息中心',
    printer: '打印机管理',
    printPreview: '单据打印预览',
    // 物流管理
    logisticsManagement: '物流管理',
    logisticsDetails: '物流单详情',
    logistics: '物流单列表',
    deliveryNotice: '发货通知单列表',
    deliveryNoticeDetails: '发货通知单详情',
    addDeliveryNotice: '发货通知单',
    electronicExpressWaybill: '电子面单模板管理',
    systemConfig: '系统参数设置',
    // 报损管理
    consumeManagement: '报损管理',
    // 反馈建议
    feedbackSuggestion: '反馈建议',
    feedbackSetting: '反馈设置',
    feedbackSuggestionList: '反馈建议列表',
    addFeedbackSuggestion: '提交反馈建议',
  },
  common: {
    status: '状态',
    selectStatus: '请选择状态',
    createTime: '创建时间',
    operation: '操作',
    menu: '菜单',
    button: '按钮',
    startUsing: '启用',
    disabled: '禁用',
    keyword: '关键词',
    inputKeyWord: '请输入关键词',
    createUser: '创建人',
    usingSuccess: '启用成功',
    disabledSuccess: '禁用成功',
    man: '男',
    woman: '女',
    remark: '备注信息',
    unKnownStatus: '未知状态',
    yuan: '元',
    repair: '维修中',
    statusUpdateStatus: '状态更新成功',
    createUserName: '下单人',
    inputCreateUserName: '请填写下单人姓名',
    startPlaceholder: '开始日期',
    endPlaceholder: '结束日期',
    moreOpetations: '更多操作',
    inputRemark: '请填写备注信息',
    waitingUpload: '等待上传',
    clickToPreview: '点击预览文件',
    all: '全部',
    select: '请选择',
    yes: '是',
    no: '否',
    download: '下载',
    index: '序号',
  },
  navbar: {
    logout: '退出登录',
    dashboard: '首页',
    profile: '个人中心',
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有',
  },
  table: {
    loadingText: '拼命加载中...',
    summaryText: '合计',
  },
  button: {
    cancel: '取消',
    ok: '确定',
    submit: '提交',
    add: '新增',
    search: '搜索',
    edit: '修改',
    delete: '删除',
    reset: '重置',
    using: '启用',
    disabled: '禁用',
    import: '导入',
    select: '选择',
    confirmSelect: '确认选择',
    confirmImport: '确认导入',
    packUp: '收起',
    more: '更多',
    unfold: '展开',
    fold: '折叠',
    checkAll: '全选',
    clearAll: '清空',
    back: '返回',
    export: '导出',
    repairing: '维修中',
    download: '下载',
    batchCancel: '批量取消',
    batchDelete: '批量删除',
    distribute: '派单',
    confirmComplete: '确认完成',
    distributeOrder: '下发生产',
    nextStep: '下一步',
    prevStep: '上一步',
    submitAndSend: '提交并派单',
    submitApproval: '提交审批',
    approval: '审批',
    approvalSuccess: '通过审批',
    approvalFailed: '未通过审批',
    accept: '接受',
    reject: '拒绝',
    exportDetail: '导出明细',
    take: '接单',
    finish: '完成',
    save: '保存',
    close: '关闭',
    bindProcess: '绑定工序',
    updateProgress: '更新进度'
  },
  operationRes: {
    cancelDelete: '已取消删除',
    addSuccess: '新增成功',
    editSuccess: '修改成功',
    deleteSuccess: '删除成功',
    addFailed: '删除失败',
    editFailed: '修改失败',
    deleteFailed: '删除失败',
    cancelReset: '取消重置',
    resetSuccess: '重置成功',
    operationCanceled: '操作已取消',
    cancelOperation: '已取消操作',
    operationSuccess: '操作成功',
    saveSuccess: '保存成功',
    operationFailed: '操作失败',
  },
  tip: {
    tipInfo: '提示信息',
    closeTip: '确定要关闭吗？',
    confirmDelete: '确定要删除选中的数据吗？',
    resetPassword: '确定重置用户密码为',
    confirmTitle: '确认信息',
    confirmReload: '请确认是否需要重新加载页面信息？',
    stayCurrentPage: '停留在当前页面',
    confirmCancel: '确认取消选中记录？',
    selectReqirements: '请选择符合要求的数据！',
  },
  components: {
    changeLanguageTips: '切换语言成功',
    tagRepeat: '标签重复',
    selectRegionTip: '请选择地区',
    selectCustomer: '请选择客户',
  },
  datePicker: {
    startPlaceholder: '开始日期',
    endPlaceholder: '结束日期',
  },
  minio: {
    initError: '服务暂时不可用，请检查网络状态，并重试!',
    bucketNotExist: '未找到模板文件，请联系管理员!',
  },
  lodop: {
    printUnInstallTip: '您还未安装打印控件，点击下载打印控件，安装成功后刷新页面即可进行打印。',
    loading: '打印控件还没准备好，请稍后再试！',
  },
  dialog: {
    selectPosition: '选择岗位',
    selectPersonnel: '选择人员',
    importExceed: '最多只能选择一份文件',
    dragUpload: '将文件拖到此处，或',
    clickUpload: '点击上传',
    acceptExtensionTip: '支持的扩展名：',
    maxUploadSizeTip: '文件大小最大为',
    downloadTemplate: '下载导入模板',
    importClassify: '导入分类',
    importLoadingText: '拼命导入中...',
    importSuccess: '导入成功',
    importErrorTip: '失败信息如下：',
    importErrorRow: '失败所在行数:',
    importErrorReason: '失败原因为：',
  },
  validate: {
    mobileIllegal: '手机号不合法',
    inputLegalTel: '请填写合法的电话号码',
  },
  requestError: {
    // 公共异常
    unknownError: '系统错误',
    _10001: '参数无效',
    _10002: '参数不能为空',
    _10003: '文件读取失败',
    _10004: '文件下载失败',
    _10005: '数据导入失败',
    _10006: '该名称已存在',
    _10007: '文件中数据为空',
    _10008: '暂无数据可导出',
    _10009: '存在关键字',
    _10010: 'sql排序关键字错误',
    _10011: '签名错误',
    _10012: '工作流程图不存在',
    _10013: '数字格式错误，请检查',
    _10014: '数据已冻结',
    _10015: '数据已审批通过',
    _10016: '必填字段为空',
    _10017: '已有下游数据',
    _10018: '编码已存在',
    _10019: '该数据不允许删除',
    _10020: '数据不允许提交',
    _10021: '数据不允许取消',
    _10022: '数据不允许修改',
    _10023: '数据重复',
    _4: '请求超时',
    _42301: '访问过快，请稍后再试！',
    // 登录模块
    _20101: '用户未登录',
    _20102: '用户名或密码错误',
    _20103: '账户已被禁用',
    _20104: '没有操作权限',
    _20105: '登录信息已失效，请重新登录!',
    _20106: '登录信息已失效，请重新登录!',
    _20107: '登录信息已失效，请重新登录!',
    _20108: '验证码为空',
    _20109: '操作失败',
    _20110: '验证码过期',
    _20111: '发送验证码失败',
    _20112: '该邮箱未与任何账户建立联系',
    _20113: '公司域名不存在',
    _20114: '密码解密失败',
    _20115: '登录超时请重新登录',
    _20116: '手机号不存在请联系管理员',
    // 用户模块
    _20201: '账户无效',
    _20202: '密码错误',
    _20203: '用户名已存在',
    _20204: '邮箱重复',
    _20205: '电话号码已被使用',
    _20206: '重置密码失败',
    _20207: '管理员不允许删除',

    // 岗位管理
    _20502: '该岗位名称已存在',
    _20503: '删除失败，存在用户绑定该岗位',

    // 部门管理
    _20601: '该部门名称已存在',
    _20602: '上级部门不存在',
    _20603: '已绑定岗位数据',
    _20604: '该部门存在下级部门，请先删除下级部门',

    // 角色管理
    _21801: '该角色名称已存在',
    _21802: '该角色已在岗位中使用',

    // 人员管理
    userNameRepeat: '登录账户已存在',
    emailRepeat: '邮箱已存在',
    mobileRepeat: '手机号已存在',
    // 分类管理
    classifyNameRepeat: '该分类名称已存在',
    // 修改密码
    passwordError: '密码错误',
    // 供应商材料
    materialNameRepeat: '材料名称已被使用',
    materialCodeRepeat: '材料编号已被使用',
    // 供应商设备
    deviceNameRepeat: '设备名称已被使用',
    // 供应商产品
    printingCraftCanNotUse: '所选输出工艺无法使用',
    printingMaterialCanNotUse: '所选输出工艺无法使用',
    backendMaterialCanNotUse: '所选输出工艺无法使用',
    backendCraftCanNotUse: '所选输出工艺无法使用',
    // 发货通知单
    unDelivery: '请先进行发货',
    _22914: '发货通知单存在物流单，无法取消',
    _22813: '项次存在变更单',
    hasLogisticOrderCanNotCancel: '取消失败！该发货通知单下存在物流单，请先删除相关物流单',
    notCancelFinish: '发货单该状态不能反完成',
    // 生产订单
    productionOrderExist: '生产订单已创建',
    productionOrderNotExist: '生产订单不存在',
    productionOrderStatusNotNew: '生产订单状态不是「新建」',
    productionOrderStatusNotWaitConfirm: '生产订单状态不是「待确认」',
    productionOrderStatusNotWaitAccept: '生产订单状态不是「待接单」',
    productionOrderStatusNotWaitSchedule: '生产订单状态不是「待排产」',
    productionOrderStatusNotInProgress: '生产订单状态不是「进行中」',
    productCountOver: '下发产品数量超过了实际可下发数量',
    productionOrderDetailNotExist: '生产订单明细不存在',
    sentDownItem: '项次已下发',
    // 生产订单变更单
    existChangeLog: '已存在未执行的变更单，请先处理已有变更单',
    changeLogCanNotPush: '变更单不是「新建」状态，无法推送',
    changeLogCanNotConfirm: '变更单不是「待确认」状态，无法执行变更',
    canNotChangeComplatedOrder: '生产订单已在「待确认」或「已完成」状态，无法执行变更',
    changeLogNotExist: '变更单不存在',
    changeLogNoDetail: '变更单明细不存在',
    supplierProductNotExist: '变更供应商产品不存在',
    changeProductNotExist: '变更产品不存在',
    billInProduction: '单据正在制作中',
    // 单据管理
    orderItemTakeError: '单据接单失败',
    orderItemFinishError: '单据完成失败',
    receivingStatusUnMatched: '单据未到收货阶段',
    canNotReturnNotOpenProcess: '回退工序未开启',
    prepressUnfinished: '平台印前未完成',
    loyoutNotFound: 'LAYOUT单不存在',
    platformgPrepressNotOpen: '平台印前确稿未开启',
    orderItemExistChangeOrder: '项次存在变更单',
    // 物流
    orderNotReadyForDelivery: '单据未处于可发货状态',
    logisticsOrderSender: '发货人为空',
    logisticsOrderSenderTel: '发货人联系方式为空',
    logisticsOrderNo: '物流单号不存在',
    logisticsOrderExpressName: '物流单公司不存在',
    orderNotclose: '当前状态不允许关闭',
    // 反馈建议
    _24601: '提交权限已关闭，请联系管理员！',
    _24602: '提交未能超过时间间隔',
    _24603: '今日提交已超出上限',
  },
  httpRequestError: {
    oftenRequest: '请求过于频繁',
    otherError: '请求异常',
    unAuthorized: '未授权，请进行身份认证',
    serverDeniedAccess: '服务器拒绝访问',
    resourceNotFound: '未找到资源',
    methodDisabled: '该请求方法被禁用',
    timeout: '请求超时',
    serverError: '服务器内部错误',
    serverNotSupport: '服务器不具备完成请求的功能',
    networkError: '请求超时',
    serveDied: '服务不可用',
    neworkTimeout: '网络超时',
    httpVersionNotSupport: 'http版本不受支持',
    httpCanceled: '请求已被取消',
  },
  accountSetting: {
    basicSetting: '基本设置',
    securitySetting: '安全设置',
    email: '邮箱',
    inputEmail: '请填写邮箱地址',
    phone: '联系电话',
    inputPhone: '请填写联系电话',
    avatar: '头像',
    replaceAvatar: '更换头像',
    updateInfo: '更新信息',
    password: '账户密码',
    avatarUploadError: '头像上传失败',
  },
  messageCenter: {
    notice: '通知',
    todo: '待办',
    noNotice: '暂无通知',
    noTodo: '暂无待办',
  },
  login: {
    title: '供应商登录',
    reLoginTitle: '重新登录',
    logIn: '登录',
    username: '用户名',
    password: '密码',
    forgetPassword: '忘记密码',
    rememberPassword: '记住密码',
    any: '随便填',
    emptyUsername: '用户名不能为空',
    emptyPassword: '密码不能为空',
    passwordLength: '密码不能少于8位',
    verifyExplain: '向右滑动进行验证',
    verifyTitle: '请完成安全验证',
    getVerifyCodeFailed: '获取验证码失败',
    verifySuccessfully: '验证成功',
    verifyFailed: '验证失败',
    resetPasswordTitle: '重置密码',
    domain: '企业域名',
    emptyDomain: '企业域名不能为空',
  },
  resetPassword: {
    domain: '企业域名',
    enterDomain: '请填写企业域名',
    domainEmpty: '企业域名不能为空',
    email: '邮箱',
    verifyCode: '验证码',
    password: '新密码',
    checkPassword: '确认密码',
    sendVerifyCode: '发送',
    enterEmail: '请填写账号绑定的邮箱地址',
    enterVerifyCode: '请填写验证码',
    enterPassword: '请填写新密码',
    enterCheckPassword: '请确认新密码',
    emailEmpty: '请填写邮箱',
    emailError: '邮箱地址不合法',
    verifyCodeEmpty: '请填写验证码',
    passwordEmpty: '请填写密码',
    passwordError: '密码格式不符合要求',
    checkPasswordEmpty: '请再次输入密码',
    passwordNotEqual: '两次输入密码不一致',
    resetSuccess: '重置成功',
    passwordRules: '密码规则',
    passwordIntegrant: '密码由英文、数字、特殊字符组成',
    passwordLength: '密码由长度为8-20位',
    passwordMustBe: '必须包含大写字母，特殊字符',
  },
  department: {
    departmentName: '部门名称',
    sort: '排序',
    inputSort: '请输入显示排序，例如1，2，3',
    sortEmpty: '显示排序不允许为空',
    parentDepartment: '上级部门',
    selectParentDepartment: '请选择上级部门',
    inputDepartmentName: '请输入部门名称',
    nameEmpty: '部门名称不能为空',
    nameSpace: '部门名称不能包含空格',
    addDepartment: '新增部门',
    editDepartment: '修改部门',
    department: '部门',
    selectDepartment: '请选择部门',
    selectProcess: '请选择工序',
  },
  bindProcessName: {
    prepress: '印前',
    printing: '输出',
    backend: '后道',
  },
  role: {
    addRole: '添加角色',
    editRole: '修改角色',
    name: '角色名称',
    code: '角色编号',
    inputName: '请输入角色名称',
    inputRemark: '请输入备注信息',
    nameEmpty: '角色名称不能为空',
    permissionAllot: '权限分配',
    selectPermission: '请选择角色可以拥有的权限',
    role: '角色',
    selectRole: '请选择角色',
    permissionConfig: '权限配置',
  },
  position: {
    addPosition: '添加岗位',
    editPosition: '修改岗位',
    parentName: '上级岗位',
    name: '岗位名称',
    code: '岗位编号',
    affiliationDepartment: '所属部门',
    selectAffiliationDepartment: '请选择所属部门',
    inputName: '请填写岗位名称',
    selectParentPosition: '请选择上级岗位',
    position: '岗位',
    selectPosition: '请选择岗位',
    topLevel: '无上级岗位',
  },
  personnel: {
    addPersonnel: '添加人员',
    editPersonnel: '修改人员',
    userName: '用户名',
    realName: '真实姓名',
    code: '员工编号',
    positonName: '岗位',
    email: '邮箱地址',
    mobile: '手机号码',
    inputUserName: '请填写登录账号',
    inputRealName: '请填写真实姓名',
    inputEmail: '请填写邮箱',
    inputMobile: '请填写手机号',
    selectPosition: '请选择岗位',
    pisition: '岗位(可多选)',
    gender: '性别',
    initPassword: '用户初始密码为：123456',
    importPersonnel: '导入人员',
  },
  user: {
    editPassword: '修改密码',
  },
  supplierMaterial: {
    code: '材料编号',
    name: '材料名称',
    category: '材料分类',
    brand: '品牌',
    size: '尺寸(宽x长)',
    unit: '计量单位',
    displayOrder: '显示顺序',
    import: '导入材料',
    selectCategory: '请选择材料分类',
    inputName: '请填写材料名称',
    inputCode: '请填写材料编号',
    inputBrand: '请填写品牌',
    selectUnit: '请选择计量单位',
    inputWidth: '请填写宽度',
    inputLength: '请填写长度',
    inputDisplayOrder: '请填写显示顺序',
    inputRemark: '请填写备注',
    addMaterial: '新增材料',
    editMaterial: '编辑材料',
    exportPrintingMaterial: '导出输出材料',
    exportBackendMaterial: '导出后道材料',
    printingTemplateName: '输出材料导入模板.xlsx',
    backendTemplateName: '后道材料导入模板.xlsx',
  },
  materialUnit: {
    meter: '米',
    centimetre: '厘米',
    millimetre: '毫米',
  },
  materialType: {
    printing: '输出',
    backend: '后道',
  },
  supplierDevice: {
    deviceName: '设备名称',
    categoryName: '设备类型',
    printHead: '喷头信息',
    colorInfo: '颜色信息',
    category: '类型',
    selectCategory: '请选择设备类型',
    importDevice: '导入设备',
    exportDevice: '导出设备',
    deviceTemplate: '设备导入模板.xlsx',
    inputDeviceName: '请填写设备名称',
    inputPrintHead: '请填写喷头信息',
    inputColorInfo: '请填写颜色信息',
    inputRemark: '请填写备注',
    addDevice: '新增设备',
    editDevice: '修改设备',
  },
  product: {
    templateName: '供应商产品导入模板.xlsx',
    import: '导入产品',
    productCode: '产品编号',
    categoryName: '产品分类',
    platformProductName: '平台产品',
    productName: '产品名称',
    price: '产品单价',
    priceUnit: '计价单位',
    weight: '克重',
    addProduct: '新增产品',
    editProduct: '修改产品',
    selectCategoryName: '请选择产品分类',
    selectPlatformProduct: '请选择平台产品',
    inputPrice: '请填写产品单价',
    selectPriceUnit: '请选择计价单位',
    inputWeight: '请填写产品克重',
    selectPrintingCraft: '请选择输出工艺',
    selectPrintingMaterial: '请选择输出材料',
    selectBackendCraft: '请选择后道工艺',
    selectBackendMaterial: '请选择后道材料',
    printingCraft: '输出工艺',
    printingMaterial: '输出材料',
    backendCraft: '后道工艺',
    backendMaterial: '后道材料',
    inputRemark: '请填写备注',
  },
  chargeUnit: {
    squareMeter: '平方米',
    piece: '张',
    cover: '套',
    individual: '个',
  },
  order: {
    orderStatus: '订单状态',
    customer: '所属客户',
    createDate: '下单日期',
    selectStatus: '请选择状态(可多选)',
    selectCustomer: '请选择客户',
    startPlaceholder: '开始日期',
    endPlaceholder: '结束日期',
    code: '订单编号',
    projectName: '项目名称',
    customerName: '所属客户',
    supplierName: '供应商',
    progress: '生产进度',
    createUserName: '下单人',
    createTime: '下单时间',
    batchDistribute: '批量派单',
    new: '新建',
    waitingAccept: '待接单',
    waitingPlan: '待排产',
    inProgress: '生产中',
    waitingConfirm: '待确认',
    completed: '已完成',
    confirmDistribute: '确认派发选中订单?',
    distributeSuccess: '派单成功',
    distributeCancel: '派单取消',
    selectNewOrderTip: '请勾选【新建】状态的单据',
    selectWaitingAccpetOrderTip: '请勾选【待接单】状态的单据',
    confirmCancel: '确认取消选中订单?',
    cancelSuccess: '订单取消成功',
    orderCompleted: '订单已完成',
    requiredDeliveryTime: '要求出货时间',
    requiredArriveTime: '要求到货时间',
    remark: '备注信息',
    orderAmount: '订单金额',
    productList: '产品清单',
    shipmentList: '物流跟踪',
    goOrderList: '去订单列表',
    createAgain: '再次创建',
    createSuccessText: '订单创建成功',
    selectSupplier: '选择供应商',
    pleaseUploadFile: '请上传文件',
    inputRemark: '请填写备注信息',
    type: '订单类型',
    online: '线上订单',
    offline: '线下订单',
    orderDetail: '订单明细',
    productionFile: '制作文件',
    oneKeyImport: '一键导入',
    selectProduct: '选择产品',
    relatedProduct: '关联产品',
    inputBase: '填写基本信息',
    distributionProduct: '分配生产内容',
    createSuccess: '创建完成',
    pleaseDistributeProduct: '请分配订单内容',
    pleaseSelectSupplierProduct: "项次'{0}'未匹配供应商产品",
    batchRelateProducts: '批量关联产品',
    selectSupplierProduct: '请选择供应商产品',
    selectCustomProduct: '请选择客户产品',
    editRelateProducts: '修改关联产品',
    productTotalCount: '产品总数',
    totalArea: '生产面积(㎡)',
    cardArea: '生产面积',
    acceptUserName: '接单人',
    finishTime: '完成时间',
    confirmProduction: '确认排产',
    submitComplete: '提交验收',
    submitCompleteTip: '确认提交订单验收?',
    confirmProductionTip: '确认更新订单为已排产?',
    acceptTip: '确认接受该订单?',
    rejectTip: '确认拒绝该订单?',
    inputReason: '请填写拒绝原因',
    shopList: '门店列表',
    selectInProgressTip: '只有进行中项目可以提交完成',
    downloadFile: '文件下载',
    cancel: '取消接单',
    cancelTip: '是否确认取消订单？',
    materialClassStatistics: '物料汇总',
    urgentOrder: '是否急单',
  },
  orderProduct: {
    itemCode: '单据编号',
    itemType: '单据类型',
    status: '单据状态',
    pointName: '点位名称',
    platformProductName: '制作品名',
    customProductName: '客户产品',
    supplierProductName: '供应商产品',
    count: '产品数量',
    totalArea: '总面积(㎡)',
    visibleWidth: '可见宽度(mm)',
    visibleHeight: '可见高度(mm)',
    completeWidth: '完成宽度(mm)',
    completeHeight: '完成高度(mm)',
    detailVisibleSize: '可见尺寸',
    detailCompleteSize: '完成尺寸',
    prepressPicture: '印前画面',
    prepressDescription: '印前排版说明',
    remark: '备注信息',
    requiredDeliveryTime: '要求出货时间',
    requiredArriveTime: '要求到货时间',
    currentProcess: '当前工序',
    finishTime: '完成时间',
    brokenLog: '报损记录',
    noProductionNeed: '无需制作',
    prepress: '印前',
    printing: '输出',
    backend: '后道',
    salePrice: '销售单价',
    purchasePrice: '采购单价',
    chargeUnit: '计价单位',
    visibleSize: '可见尺寸(mm)',
    completeSize: '完成尺寸(mm)',
    productArea: '产品面积',
    detailTitle: '产品详情',
    baseInfo: '基本信息',
    progress: '制作进度',
    backendCrafts: '后道工艺',
    shopName: '门店名称',
    prepressFlag: '印前工序',
    printingFlag: '输出工序',
    backendFlag: '后道工序',
    customerName: '客户名称',
    projectName: '项目名称',
    takeUserName: '接单人',
    takeTime: '接单时间',
    productName: '产品名称',
    prepressCrafts: '印前工艺',
    prepressConfirmFile: '画面预览',
    clickToPreviewPDF: '点击预览PDF',
    createUserName: '下单人',
    keywords: '项次编号/制作品名',
    isConsumeRemake: '是否为耗损重制',
    flagOpen: '开启',
    flagClose: '关闭',
    areaPriceFlag: '面积计价',
  },
  orderShop: {
    shopCode: '门店编号',
    shopName: '门店名称',
    productionProgress: '生产进度',
    logisticsProgress: '出货进度',
    address: '门店地址',
    contact: '门店电话',
    shopManager: '店长',
    receiver: '收货人',
    receiverTel: '收货人电话',
    upload: '上传首样图片',
    selectArea: '请选择地区',
    projectName: '关联项目',
    customerName: '关联客户',
    orderCode: '关联订单',
    productList: '产品清单',
  },
  /**
   * 工序状态
   */
  processStatus: {
    new: '未制作',
    waiting: '待制作',
    processing: '制作中',
    completed: '已完成',
    closed: '不制作',
  },

  /**
   * layout单打印状态
   */
  layoutStatus: {
    notGenerate: '未生成',
    generate: '已生成',
  },
  /**
   * 订单项次产品状态
   */
  orderProductStatus: {
    waitPlan: '待排产',
    new: '待制作',
    processing: '制作中',
    completed: '已完成',
    canceled: '已取消',
  },
  orderProductType: {
    product: '成品',
    sample: '样品',
    time: '工时服务',
  },
  processActionEnum: {
    take: '接单',
    finish: '完成',
    process: '更新进度',
  },
  operationTypeEnum: {
    prepress: '印前',
    print: '输出',
    backend: '后道',
  },
  overtimeStatusEnum: {
    notOvertime: '未超时',
    willOvertime: '即将超时',
    overtime: '已超时',
  },
  brokenLog: {
    brokenType: '耗损类型',
    brokenProcess: '耗损工序',
    count: '耗损数量',
    priceUnit: '计量单位',
    area: '耗损面积(m²)',
    reason: '耗损原因',
    dutyUser: '责任人',
    remark: '备注说明',
    completeSize: '完成尺寸(mm)',
    createUserName: '上报人',
    createTime: '上报时间',
    orderChangeLogCode: '关联变更单',
    brokenItem: '耗损项次',
  },
  orderLogisticsOrder: {
    code: '发货单号',
    shippingType: '出货方式',
    shippingCompany: '物流公司',
    shippingCode: '物流单号',
    status: '物流状态',
    receiveAddress: '收货地址',
    receiver: '收货人',
    contact: '联系电话',
    receiveTime: '收货时间',
    sendAddress: '发货地址',
    sender: '发货人',
    sendTime: '发货时间',
    receiveInfo: '收货信息',
    sendInfo: '发货信息',
    logisticsInfo: '物流信息',
    count: '产品数量',
    remark: '备注信息',
    parentOrder: '母单号',
  },
  shippingType: {
    express: '快递',
    selfPick: '自提',
    carDelivery: '专车',
  },
  logisticsOrderStatus: {
    new: '待发货',
    receivedGoods: '待揽收',
    inTransit: '在途中',
    error: '异常',
    received: '已签收',
    closed: '已关闭',
  },
  logisticsStatus: {
    new: '待发货',
    toBeCollect: '揽收',
    inTransit: '在途',
    dispatch: '派件',
    received: '签收',
    back: '退回',
    signBack: '退签',
    conversion: '转投',
    diffcult: '疑难',
    customsClearance: '清关',
    refusalToSign: '拒签',
  },
  orderChange: {
    changeDate: '变更日期',
    changeTime: '变更时间',
    changeMan: '变更人',
    code: '单据编号',
    orderCode: '所属订单',
    projectName: '项目名称',
    customerName: '所属客户',
    supplierName: '供应商',
    status: '单据状态',
    changeReason: '变更原因',
    selectNewOrFailed: "请选择'新建'或'审核未通过'的单据",
    selectPendingApproval: "请选择'审核中'或'待确认'的单据",
    submitApprovalSucces: '已提交审批',
    submitApproval: '提交审批',
    selectStatus: '选择状态',
    confirmCancel: '确认取消选中单据?',
    approval: '审批',
    approvalDescription: '审批说明',
    inputApprovalDescription: '请填写审批说明',
    approverName: '审批人',
    approvalTime: '审批时间',
    changeDetail: '变更明细',
    confirmTime: '确认时间',
    changeConfirm: '通过',
    confirmTip: "点击'编号'进入详情页面进行单据确认",
    submitConfirmTip: '请确认是否知晓变更内容?',
    reject: '驳回',
    reviewRejected: '审核驳回',
    refuseReason: '审批意见',
    rejectReason: '驳回原因',
    inputRefuseReason: '请输入审批意见',
  },
  orderChangeStatus: {
    new: '新建',
    pendingApproval: '审核中',
    approvalFailed: '审核未通过',
    pendingConfirm: '待审批',
    reject: '已驳回',
    finished: '已通过',
  },
  changeType: {
    edit: '修改信息',
    minusCount: '减少数量',
    stopProduction: '停止生产',
    addProduct: '增加产品',
    addCount: '增加数量',
  },
  orderChangeDetail: {
    changeType: '变更类型',
  },
  receipt: {
    status: '单据状态',
    customer: '所属客户',
    inputKeyWord: '单据编号/订单编号',
    selectCustomer: '请选择客户',
    orderCode: '所属生产订单编号',
    createDate: '下单日期',
    export: '单据明细',
    itemCode: '单据编号',
    brokenReport: '报损',
    timeout: '已超时',
    timeoutComingUp: '即将超时',
    selectReceipts: '请选择待制作且平台印前已完成的单据!',
    finishReceiptsTip: '确认完成选中单据?',
    selectProcessingReceipts: '请勾选制作中单据',
    takeDialogTitle: '单据接单',
    device: '生产设备',
    selectDevice: '选择设备',
    selectDeviceTip: '请选择设备',
    takeReceiptsTip: '确认制作选中单据?',
    deliveryDate: '出货日期',
    screenArea: '画面面积(㎡)',
    screenRate: '画面比例',
    totalArea: '合计面积',
    platformPrepressStatus: '平台印前状态',
    prepressStatus: '印前状态',
    printStatus: '输出状态',
    backendStatus: '后道状态',
    platformPrepressUnDoneTip: '平台印前未完成，无法接单!',
    edited: '请注意，该单据已被修改过!',
    selectItemType: '请选择单据类型',
    inputProductName: '请填写产品名称',
    inputPrepressPicture: '请填写印前画面',
    productionFile: '生产文件',
    thumbnails: '确稿画面预览',
    preview: '预览',
    notUpload: '未上传',
    printCount: '打印次数',
    inputprintCount: '请输入打印次数',
    selectPrintReceipts: '已选数据均不可打印，请选择符合条件的单据',
    printLayout: '打印Layout单',
    recreateLayout: '更新layout单',
    nothingPrintList: '没有可打印的单据',
    limitExceeded: '一次最多打印100张',
    layoutStatus: 'layout单状态',
    isDepletion: '是否耗损',
    lastPrintTime: '打印时间',
    lastPrintUserName: '打印人',
    selectProcessingReceiptTip: '请选择制作中单据'
  },
  productBroken: {
    itemCode: '单据编号',
    backProcess: '回退工序',
    brokenType: '耗损类型',
    dialogTitle: '耗损上报',
    unit: '计量单位',
    selectUnit: '请选择计量单位',
    inputCount: '请填写耗损数量',
    inputRemark: '请填写备注',
    dutyUser: '责任人',
    inputDutyUser: '请填写责任人',
    dutyOrganize: '责任部门',
    inputDutyOrganize: '请填写责任部门',
    findUser: '发现人',
    inputFindUser: '请填写发现人',
    findOrganize: '发现部门',
    inputFindOrganize: '请填写发现部门',
    count: '耗损数量',
    dutyReason: '责任原因',
    inputDutyReason: '请填写耗损原因',
    selectBrokenProcess: '请选择回退环节',
    consumedNumIllegal: '耗损数量不能大于产品总数',
    brokenArea: '耗损面积(㎡)',
    inputBrokenArea: '请填写耗损面积',
    consumeStage: '耗损环节',
    dutyAgencyType: '责任机构类型',
    consumeInfo: '耗损信息',
  },
  sender: {
    addSender: '新增发件人',
    editSender: '编辑发件人人',
    senderName: '发件人姓名',
    inputName: '请填写发件人姓名',
    senderNumber: '联系电话',
    inputNumber: '请填写联系电话',
    area: '发货地区',
    selectArea: '请选择发货地区',
    address: '详细地址',
    inputAddress: '请填写详细发货地址',
  },
  printer: {
    addPrinter: '新增打印机',
    editPrinter: '编辑打印机',
    name: '打印机名称',
    inputName: '请填写打印机名称',
    model: '打印机型号',
    inputModel: '请填写打印机型号',
    ip: '打印机IP',
    inputIP: '请填写打印机IP',
    port: '打印机端口号',
    inputPort: '请填写打印机端口号',
  },
  deliveryDetails: {
    code: '单据编号',
    selectSupplier: '请选择供应商',
    relatedProjects: '关联项目: ',
    relatedCustomer: '关联客户: ',
    shopDetails: '门店明细',
    productDetails: '产品明细',
    customSite: '自定义地点',
    addSite: '添加地点',
    importDeliveryDetails: '导入发货明细',
    selectProduct: '选择产品',
    deliverySite: '交货地点',
    deliveryAddress: '交货地址',
    receiver: '收货人',
    receiverTel: '收货电话',
    supplier: '供应商: ',
    deliveryRemark: '发货说明',
    remark: '备注信息: ',
    inputReceiver: '请填写收货人',
    inputReceiverTel: '请填写收货人电话',
    area: '所在地区',
    selectArea: '请选择所在地区',
    address: '详细地址',
    inputAddress: '请填写详细地址',
    addDeliverySite: '新增交货地点',
    editDeliverySite: '编辑交货地点',
    shopName: '交货地点',
    inputShopName: '请填写交货地点',
    sendCount: '本次发货数量',
    selectProductItem: '选择项次',
    productClassify: '产品分类',
    platformProduct: '平台产品',
    customerProduct: '客户产品',
    projectProductKey: '请输入关键字进行搜索（支持项次编号、点位名称）',
    addDeliveryNotice: '新建物流通知单',
    editDeliveryNotice: '编辑发货通知单',
    remainDeliveryCount: '发货数量已超出项次内产品总数，该项次剩余可发货数量为',
    remainCount: '剩余可发货数量',
    posterTemplateName: '海报类交货明细',
    popTemplateName: 'Pop类交货明细',
    importTitle: '导入交货明细',
    importLogisticsTitle: '导入物流单',
    exportPosterDetails: '导出海报类明细',
    exportPopDetails: '导出Pop类明细',
    logisticsOrderTotal: '已创建物流单数量',
    isCreatedLogistics: '是否创建过物流单',
    plannedShippingTime: '计划出货时间',
  },
  projectProduct: {
    itemCode: '项次编号',
    customerName: '客户名称',
    productionProgress: '制作进度',
    pointName: '点位名称',
    consumed: '耗损',
    status: '状态',
    finishTime: '完成时间',
    consumedCode: '耗损项次',
    copy: '复制',
    templateName: '产品明细导入模板',
    keywords: '支持项次编号、产品名称',
    itemType: '项次类型',
    finishedProduct: '产成品',
    visibleSize: '可见尺寸(mm)',
    finishSize: '完成尺寸(mm)',
    platformProduct: '制作品名',
    customerProduct: '客户产品',
    salePrice: '销售单价',
    priceUnit: '计价单位',
    backendCrafts: '后道工艺',
    requiredDeliveryTime: '要求出货时间',
    requiredArriveTime: '要求到货时间',
    count: '项次数量',
    deliveryNumber: '发货数量',
    remark: '备注',
    prepressPicture: '印前画面',
    isOverdue: '是否逾期',
    toBeTimeout: '即将逾期',
  },
  deliveryNoticeStatus: {
    new: '待推送',
    waitTakeOrder: '待接单',
    waitDelivery: '待发货',
    partDelivery: '部分发货',
    delivered: '已发货',
    complete: '已完成',
  },
  deliveryNoticeList: {
    code: '单据编号',
    status: '单据状态',
    customer: '所属客户',
    orderCreateTime: '下单日期',
    supplier: '供应商',
    project: '关联项目',
    deliveryRemark: '发货说明',
    selectCustomer: '请选择选择客户',
    selectStatus: '请选择单据状态',
    selectOrderCreateTime: '请选择下单时间',
    deliveryConfirm: '单据确认',
    deliveryComplete: '完成发货',
    inputCode: '请填写单据编号',
    isConfirm: '确定要【确认】选中的发货通知单吗？（只支持待接单状态的单据）',
    onlyWaitTakeOrder: '当前选中的通知单状态均不符合要求，请选择状态为 ”待接单“ 的发货通知单！',
    isComplete: '确定要【完成】选中的发货通知单吗？（只支持待发货状态的单据）',
    onlyWaitDelivery: '当前选中的通知单状态均不符合要求，请选择状态为 ”待发货“ 的发货通知单！',
    requiredDeliveryTime: '要求出货时间',
    requiredArriveTime: '要求到货时间',
    exportMaxTip: '导出明细时最多只能选择一条发货通知单',
    shopTotal: '门店数量',
    itemTotal: '产品数量',
    cancelTakeOrderTip: '确定要取消接单吗？',
    cancelComplete: '反完成',
    cancelFinish: '确定要【反完成】选中的发货通知单吗？（只支持已完成状态的单据)',
    onlyComplete: '当前选中的通知单状态均不符合要求，请选择状态为 ”已完成“ 的发货通知单！',
  },
  electronicExpressWaybill: {
    add: '新建电子面单模板',
    edit: '编辑电子面单模板',
    name: '电子面单名称',
    inputName: '请填写面单名称',
    expressCompany: '快递公司',
    selectExpressCompany: '请选择快递公司',
    expressType: '产品类型',
    selectExpressType: '请选择产品类型',
    templateStyle: '模板样式',
    selectTemplate: '请选择模板样式',
    account: '月结账号',
    inputAccount: '请填写月结账号',
    password: '月结密码',
    inputPassword: '请填写月结密码',
    receiptShops: '收件网点',
    inputReceiptShops: '请填写收件网点名称',
    paymentMode: '支付方式',
    selectPaymentMode: '请选择支付方式',
    printer: '关联的打印机',
    selectPrinter: '请选择关联的打印机',
    isDefault: '是否设为默认',
    deliveryFeePrepaid: '寄付现结',
    deliveryFeeToBeCollected: '到付现结',
    deliveryFeeMonthly: '寄付月结',
    yes: '是',
    no: '否',
    partnerSecret: '客户秘钥',
    inputPartnerSecret: '请填写客户秘钥',
  },
  deliveryMode: {
    TPL: '第三方物流',
    specialTrain: '专车',
    selfPickUp: '自提',
  },
  logistics: {
    add: '添加物流单',
    selectDelivertMode: '请选择出货方式',
    inputShopCount: '请填写每店单数',
    inputDeliveryRemark: '请填写发货备注',
    deliveryMode: '出货方式',
    shopCount: '每店单数',
    deliveryRemark: '发货备注',
    shippingInfo: '配送信息',
    logisticsInfo: '物流信息',
    expressCompany: '物流公司',
    logisticsNo: '物流单号',
    receiverInfo: '收货信息',
    senderInfo: '发货信息',
    subscribeStatus: '轨迹订阅状态',
    subscribeState: '订阅状态',
    code: '单据编号',
    inputCode: '请输入单据编号',
    relevanceDeliveryBill: '关联发货单',
    relevanceCustomer: '关联客户',
    relevanceProject: '关联项目',
    relevanceShop: '关联门店',
    childCount: '包裹数量',
    inputChildCount: '请填写包裹数量',
    status: '单据状态',
    logisticsStatus: '物流状态',
    newLogisticsTrack: '最新轨迹',
    selectCustomer: '请选择关联客户',
    seelctOrderStatus: '请选择单据状态',
    seelctStatus: '请选择物流状态',
    delivery: '发货',
    importDelivery: '导入发货',
    receiving: '收货',
    waybillPrint: '电子面单打印',
    batchChangeCost: '批量修改费用',
    changeInfo: '修改收发货信息',
    export: '导出物流单',
    subscribeTrack: '轨迹订阅',
    subscribeSuccess: '订阅成功',
    unSubscribe: '未订阅',
    confirmDelivery: '是否确认发货',
    confirmReceiving: '确定要对选中的单据进行收货操作吗？（只支持”在途中“状态的单据）',
    confirmDelete: '确定要删除选中的单据吗？（只支持”待发货“状态的单据）',
    deliveryTime: '发货日期',
    receivingTime: '收货日期',
    templateName: '物流单导入发货模板',
    downloadFileName: '物流单',
    logisticsCost: '物流费用',
    weight: '重量(千克)',
    price: '价格(元)',
    inputCost: '请填写物流费用',
    inputWeight: '请填写重量',
    batchSet: '批量设置',
    inputDeliveryUser: '请填写发货人',
    inputReceiveUser: '请填写收货人',
    inputDeliveryTel: '请填写发货人联系方式',
    inputReceivingTel: '请填写收货人联系方式',
    selectReceiveArea: '请选择收货地区',
    selectDeliveryArea: '请选择发货地区',
    inputDeliveryAddress: '请填写发货地址',
    inputReceiveAddress: '请填写收货地址',
    selectExpressCompany: '请选择物流公司',
    selectRequiredArriveTime: '请选择要求到达时间',
    inputLogisticsNo: '请填写物流单号',
    deliveryArea: '发货地区',
    receiveArea: '收货地区',
    arriveTime: '到达时间',
    inTransit: '运输中',
    logisticsTrack: '物流轨迹',
    mapMode: '地图模式',
    deliveryUser: '发件人',
    selectDeliveryUser: '请选择发件人',
    electronicBill: '电子面单',
    selectElectronicBill: '请选择电子面单',
    confirmPrint: '下单成功！需要立刻打印电子面单吗',
    takeElectronicBill: '电子面单下单',
    startGotTime: '预约取件时间',
    selectStartGotTime: '请选择预约取件时间',
    cancleDelivery: '取消发货',
    cancelDeliveryTip: '确定要取消发货吗？(只支持单据状态为【待揽收】的单据)',
    noLogisticsTrack: '暂未查询到相关物流轨迹信息',
    importFare: '导入运费',
    importFareTips: '运费导入excel文件的表头标题必须包含物流单号、重量、运费，且表头标题必须放在第二行！',
    receivingCity: '收货城市',
    inputReceivingCity: '请填写收货城市',
    selectDeliveryMode: '请选择运输方式',
    shopName: '门店名称',
    inputShopName: '请填写门店名称',
    productList: '产品列表',
    requiredArriveTime: '要求到达',
    arrivalTime: '预计到达',
    batchCloseTitle: '关闭单据',
    closeReason: '关闭原因',
    inputCloseReason: '请填写关闭原因',
  },
  systemConfig: {
    lodopPort: '打印控件端口',
    inputLodopPort: '请填写打印控件端口号',
    lodopPortTip: '使用打印机前请确保安装了打印机控件，安装后根据实际情况填写打印控件端口并保存',
  },
  projectItemType: {
    finishedProduct: '成品',
    changeColor: '样品',
  },
  prepressLayoutCrafts: {
    noneAdjustmentToPrint: '原档输出',
    dimensionAdjustment: '调整尺寸&客户指定内容',
    accordingToGuideline: '按guideline排版',
    ratioLayout: '比例档',
    printReadyFile: '外发文件',
    pixelLayout: '像素排版',
    others: '其他',
    accordingPretty: '依美观排版',
    accordingPrtion: '依比例档排版',
    fence: '围挡排版',
    showbill: '吊旗排版',
    lineDraft: '线稿排版',
    copyPrint: '调打',
  },
  prepressChangeColorCrafts: {
    noneAdjustmentToPrint: '原档输出',
    accordingToTheProof: '依客户样对色',
    graphicRetouch: '修图补图',
    commonSense: '颜色漂亮美观',
    softPrinting: '依屏幕色改色',
    others: '其他',
    accordingColorCard: '依指定色票对色',
  },
  consume: {
    export: '耗损导出文件',
    createUserName: '下单人',
    createTime: '下单日期',
    inputPlatformProduct: '请填写制作品名',
    inputItemCode: '请填写项次编号',
    consumeStage: '耗损环节',
    selectConsumeStage: '请选择耗损环节',
    dutyAgencyType: '责任机构类型',
    selectDutyAgencyType: '请选择责任机构类型',
    dutyUser: '责任人',
    inputDutyUser: '请填写责任人',
    consumeType: '耗损类型',
  },
  prepressStatus: {
    new: '待制作',
    waitForProduction: '待制作',
    inProduction: '制作中',
    waitForConfirm: '待确认',
    waitComplete: '待完成',
    completed: '已完成',
    consumed: '已耗损',
  },
  platformPrepressStatus: {
    notMade: '不制作',
    waitForProduction: '待制作',
    inProduction: '制作中',
    completed: '已完成',
  },
  dutyAgencyType: {
    platform: '平台自营',
    productionSupplier: '生产供应商',
    materialSupplier: '材料供应商',
    logisticsSupplier: '物流供应商',
    installSupplier: '安装供应商',
  },
  dashboard: {
    todo: '待办事项',
    today: '今日',
    thisWeek: '本周',
    thisMonth: '本月',
  },
  productionTodo: {
    toBeConfirmChange: '待处理变更单',
    toBeReceiveProduced: '待接订单',
    toBeScheduling: '待排产订单',
  },
  logisticTodo: {
    errorLogisticsOrder: '异常物流单',
    overdueLogisticsOrder: '逾期预警',
    overdueNotReceived: '逾期未签收',
    toBeReceiveShip: '待发货通知单',
    toBeShip: '待发货物流单',
  },
  productionReport: {
    title: '生产统计',
    productionArea: '已生产面积',
    inProductionArea: '生产中面积',
    waitPrintArea: '待打印面积',
    waitBackendArea: '待后道面积',
    consumedArea: '耗损面积',
    proofingArea: '打样面积',
  },
  productDeliveryReport: {
    title: '订单产品面积统计(单位:m²)',
    inputProjectCode: '请填写项目编号',
    deliveryDateName: '出货日期/产品名称',
    number: '数量',
    area: '面积',
    checkProjectCode: '请检查项目编号是否正确',
    titleTip: '根据订单编号、出货日期范围统计已接受订单中未完成生产单据数据',
  },
  exceptionReport: {
    title: '异常播报',
    logisticsExcept: '物流异常',
    slippageUnsigned: '逾期未签收',
    slippageWarning: '逾期预警',
  },
  queryPlan: {
    newTitle: '新建查询方案',
    editTitle: '更新查询方案',
    planName: '方案名称',
    plan: '方案',
    inputName: '请输入方案名称',
    operationTitle: '编辑查询方案',
  },
  feedback: {
    title: '反馈建议',
    code: '反馈编码',
    content: '反馈内容',
    type: '反馈类型',
    create: '提交日期',
    source: '反馈渠道',
    createUserName: '提交人',
    createTime: '提交时间',
    inputContent: '请填写反馈内容',
    selectType: '请选择反馈类型',
    enclosure: '附件',
    details: '反馈详情',
  },
  updateProgressDialog: {
    updateProgressDialogTitle: '更新生产进度',
    selectedCount: '已选项次总数',
    totalArea: '项次总面积(㎡)',
    finishedArea: '已完成面积(㎡)',
    finishedPercent: '已完成比例(%)',
    updatePercent: '当前完成比例(%)',
    updateArea: '当前完成面积(㎡)',
    percentValueNotChanged: '完成比例未发生变化'
  }
};
