import { render, staticRenderFns } from "./production-order.vue?vue&type=template&id=0d0a9cc0&scoped=true"
import script from "./production-order.ts?vue&type=script&lang=ts&external"
export * from "./production-order.ts?vue&type=script&lang=ts&external"
import style0 from "./production-order.scoped.scss?vue&type=style&index=0&id=0d0a9cc0&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d0a9cc0",
  null
  
)

export default component.exports