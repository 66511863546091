/**
 * 单据工序操作枚举
 */
export enum ProcessActionEnum {
    /**
     * 接单
     */
    take = 1,
    /**
     * 完成
     */
    finish = 2,
    /**
     * 过程(更新进度)
     */
    process = 3
}